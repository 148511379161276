<div (window:resize)="onResize($event)"></div>
<app-navigation></app-navigation>
<router-outlet></router-outlet>
<div class="sidenav-overlay d-none" id="sidenav-overlay" (click)="rightbar($event)"></div>
<app-footer></app-footer>
<!--<div *ngIf ="customizer === 'on'">
<app-customizer *ngIf="layout === 'vertical'"></app-customizer>
<app-horizontal-customizer *ngIf="layout === 'horizontal'"></app-horizontal-customizer>
</div>-->

